import { useEffect } from 'react'

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import {
  init as SentryInit,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/remix'
import { hydrateRoot } from 'react-dom/client'

hydrateRoot(document, <RemixBrowser />)

SentryInit({
  dsn: 'https://588364d7ce9701606dada1a4e1bfae42@o4506861024837632.ingest.us.sentry.io/4506861029818368',
  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    // Replay is only available in the client
    replayIntegration(),
  ],

  // Turn off trace sampling, we're not using it
  tracesSampleRate: 0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Disable Sentry when developing locally
  enabled:
    !window.location.hostname.startsWith('127.0.0.1') &&
    !window.location.hostname.startsWith('localhost'),
})
